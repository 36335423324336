<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">상품리뷰</template>

            <!-- 개별 상품평 -->
            <div class="border-b">
                <template v-for="item in items">
                    <review-list-item :value="item" withLink :key="item._id" />
                </template>
            </div>

            <template v-if="isLoadMore">
                <div class="btn-wrap btn-wrap--md" v-if="hasMoreItems" v-intersect.quiet="loadInfinite">
                    <v-row align="center" justify="center" class="row--sm">
                        <v-col cols="12" sm="auto">
                            <v-btn outlined color="grey darken-4" class="v-size--xx-large w-100 min-w-sm-200px" v-bind="{ loading }" @click="loadMore">MORE VIEW</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-else>
                <div class="pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </div>
            </template>
        </page-section>

        <v-fade-transition>
            <v-overlay v-show="loading" absolute light color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ReviewListItem from "@/components/client/shop/reviews/review-list-item.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ReviewListItem,
    },
    data: () => ({
        reviews: [],
        summary: { totalCount: 0 },

        limit: 10,

        loading: false,
        isLoadMore: false,
    }),
    computed: {
        items() {
            return [...this.reviews];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        hasMoreItems() {
            return this.page * this.limit < this.summary.totalCount;
        },

        headers() {
            let { skip, limit } = this;

            if (this.isLoadMore) {
                skip = this.items.length;
                limit = limit * this.page - this.items.length;
            }

            return { skip, limit, sort: JSON.stringify({ createdAt: -1, _id: -1 }) };
        },

        params() {
            let { query } = this.$route;

            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        page() {
            if (this.isLoadMore) return;
            console.log(this.page);
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.loading = false;

            this.reviews = [];
            this.summary = { totalCount: 0 };
        },

        async search() {
            if (this.isLoadMore) if (this.page == 1) await this.init();

            if (this.loading) return;
            else this.loading = true;

            try {
                let { headers, params } = this;
                let { summary, reviews } = await api.v1.shop.reviews.gets({
                    headers,
                    params,
                });

                // 로드모어
                if (this.isLoadMore) {
                    this.reviews = this.reviews.concat(reviews);
                }
                // 일반목록
                else {
                    this.reviews = reviews;
                }

                this.summary = summary;
            } finally {
                this.$nextTick(() => (this.loading = false));
            }
        },

        loadMore() {
            if (this.loading) return;
            let { page = "1", ...query } = this.$route.query;
            query.page = +page + 1;
            this.$router.replace({ query });
        },

        loadInfinite(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadMore();
        },

        redraw() {
            setTimeout(this.$redrawVueMasonry, 100);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
