var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("상품리뷰")]), _c('div', {
    staticClass: "border-b"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('review-list-item', {
      key: item._id,
      attrs: {
        "value": item,
        "withLink": ""
      }
    })];
  })], 2), _vm.isLoadMore ? [_vm.hasMoreItems ? _c('div', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect.quiet",
      value: _vm.loadInfinite,
      expression: "loadInfinite",
      modifiers: {
        "quiet": true
      }
    }],
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.loadMore
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("MORE VIEW")])], 1)], 1)], 1) : _vm._e()] : [_c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)]], 2), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5",
      "color": "primary"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }